import * as React from "react";
import { GetStaticProps, NextPage } from "next";
import Layout from "../components/Layout";
import { normalizeHeroImage } from "../routes/images";
import client from "../../client";
import RenderSections from "../components/render-sections";
import {
	extractPropertyInfo,
	GenericPageProps,
	getSiteSettings,
	pageQuery,
} from "../routes/common";
import type { Page } from "../types/generated/schema";

const HomePage: NextPage<GenericPageProps> = ({ pageData, config }) => {
	return (
		<Layout config={config} tags={pageData.seo}>
			{pageData.content && <RenderSections sections={pageData.content} />}
		</Layout>
	);
};

export default HomePage;

export const getStaticProps: GetStaticProps<GenericPageProps> = async () => {
	const homePageData = await client
		.fetch<Page>(pageQuery, { slug: "/" })
		.then((res) => ({ ...res }));

	const config = await getSiteSettings();

	if (!homePageData.content || !config) {
		return {
			notFound: true,
		};
	}

	const data = normalizeHeroImage(homePageData);
	const pageData = await extractPropertyInfo(data);

	return {
		props: {
			pageData,
			config,
		},
		revalidate: 60,
	};
};
